import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { useRouter } from 'next/router';

const useUserCashback = (productCashback = null) => {
  const isLoggedIn = useSelector((s) => s.isLoggedIn);
  const PERMISSIONS = useSelector((state) => state.permissions);
  const user = useSelector((state) => state?.user);
  const router = useRouter();
  const { lang } = router.query;

  const userCashback = useMemo(() => {
    if ((!isLoggedIn || PERMISSIONS.IS_PRIVATE) && lang === 'it_IT') {
      if (productCashback) {
        return Number(productCashback);
      } else {
        return user?.cashback?.value || 5;
      }
    } else {
      return 0;
    }
  }, [
    PERMISSIONS.IS_PRIVATE,
    isLoggedIn,
    lang,
    productCashback,
    user?.cashback?.value,
  ]);

  return {
    userCashback,
  };
};

export default useUserCashback;
