import { removeProductCart } from 'actions/ui';
import Icon from 'components/utils/Icon';
import useTranslation from 'hooks/useTranslation';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { getFileAddress, getProductPrice } from 'tools/utils';
import style from './index.style';

export default function ModalCartProduct({ product }) {
  const { TRANSLATIONS } = useTranslation();
  const dispatch = useDispatch();
  const removeProduct = useCallback(() => {
    dispatch(removeProductCart(product));
  }, [dispatch, product]);

  return (
    <div className="product d-f fd-c">
      <div className="detail-wrapper">
        <div className="row fw-n">
          <div className="image-wrapper">
            <img
              src={getFileAddress(product?.pictures?.[0])}
              alt={product.name}
            />
          </div>
          <div className="sku d-f fd-c">
            <span className="brand">{product.brand}</span>
            <span className="name">{product.code}</span>
            <div className="item size">
              <span className="label">{TRANSLATIONS.orders.new.size}</span>
              <span className="info">{product.model?.size}</span>
            </div>
            <div className="item size">
              <span className="label">{TRANSLATIONS.orders.new.quantity}</span>
              <span className="info">{product?.quantity}</span>
            </div>
            <div className="item cost">
              <span className="info">
                {getProductPrice(product.model, product.quantity)} €
              </span>
            </div>
          </div>
          <div className="remove-btn" onClick={removeProduct}>
            <div className="icon-wrapper">
              <Icon type="trash-2" />
            </div>
          </div>
        </div>
      </div>
      <div className="row"></div>
      <style jsx>{style}</style>
    </div>
  );
}
