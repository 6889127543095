import css from 'styled-jsx/css';

export default css`
  .counter {
    position: absolute;
    top: 1%;
    right: 1%;
    background: var(--error);
    width: 18px;
    height: 18px;
    border-radius: 50px;
    font-size: 12px;
    color: white;
    font-weight: 600;
  }
  .divider {
    padding: 0;
    overflow: visible;
    border: none;
    border-top: var(--line-header);
    color: var(--grey-lighter);
    text-align: center;
  }
  .noactive {
    display: flex;
    position: absolute;
    right: 8px;
    top: 30px !important;
    transition: 0.2s;
    visibility: hidden;
    opacity: 0 !important;
  }
  .active {
    transition: 0.2s;
    opacity: 1 !important;
    right: 8px;
    transform: translateY(0px);
    z-index: 11;
  }
  .cart {
    position: absolute;
    cursor: initial;
    right: 0px;
    top: 31px;
    background: var(--background-utils);
    box-shadow: var(--shadow-light-hover);
    z-index: 11;
    opacity: 1;
    border-radius: 4px;
    width: 400px;
    border-top: var(--line-header);
    border: solid thin var(--card-border);
  }
  .cart-mobile {
    cursor: initial;
    right: 0px;
    top: 0px;
    background: var(--background-utils);
    box-shadow: var(--shadow-light-hover);
    z-index: 100001;
    opacity: 1;
    border-radius: 4px;
    min-height: -webkit-fill-available
    height: 100%;
    position: fixed;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    border-top: var(--line-header);
    border: solid thin var(--card-border);
  }
  .cart-mobile-product {
    position: fixed;
    cursor: initial;
    right: 0px;
    top: 0px;
    background: var(--background-utils);
    box-shadow: var(--shadow-light-hover);
    z-index: 100001;
    opacity: 1;
    border-radius: 4px;
    width: 100%;
    border-top: var(--line-header);
    border: solid thin var(--card-border);
  }
  .cart-mobile-product-content {
    padding: 1em;
  }
  .cart-mobile-product-content p {
    font-size: 15px;
    color: var(--grey-light);
    text-align: center;
    margin-bottom: 0.5em;
  }
  :global(body .cart-mobile) {
    overflow: hidden !important;
  }
  .cart-wrapper {
    padding: 1em;
  }
  .cart-wrapper-mobile {
    padding: 1em;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    overflow: hidden scroll;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    padding-bottom: 80px;
  }
  .cart-header {
    padding: 0.5em 1em;
    border-bottom: var(--line-header);
  }
  .cart-header span {
    color: var(--grey-lighter);
    font-size: 13px;
  }
  .cart-header-icon :global(.icon) {
    font-size: 16px;
    cursor: pointer;
  }
  .cart-header-icon {
    width: 30px;
    height: 30px;
    transition: 0.3s;
  }
  .cart-header-icon:hover {
    background: var(--background);
    border-radius: 50px;
  }
  .fixed-box {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0px;
    background: var(--background-utils);
    border-top: var(--line);
    padding: 0.6rem;
    border-radius: 0px 0px 5px 5px;
    
  }
  .scrollable-section {
    max-height: 350px;
    overflow: hidden scroll;
    margin-bottom: 130px;
  }
  .m-bottom {
    margin-bottom: 80px;
  }
 
  .shipping-free-reached {
   
  }
  .shipping-free-text p,
  .shipping-free-reached p {
    font-size: 15px;
    font-weight: 400;
  }
  .shipping-free-text span {
    font-size: 15px;
    font-weight: 600;
    color: var(--primary);
  }
  .shipping-free-reached span {
    font-size: 15px;
    font-weight: 600;
  }
  .shipping-free-reached :global(.icon) {
    color: var(--accent-dark);
    font-size: 22px;
  }
  .scrollable-section::-webkit-scrollbar {
    width: 0.1em;
    position: absolute;
  }
  .scrollable-section::-webkit-scrollbar-thumb {
    background-color: var(--foreground);
    outline: 1px solid var(--foreground);
    position: absolute;
  }
  .cart-wrapper-mobile .scrollable-section {
    max-height: 100%;
    overflow: hidden scroll;
    margin-bottom: 0px;
  }
  .empty-cart {
    overflow: hidden;
    max-height: 350px;
    margin-bottom: 95px;
  }
  .empty-cart img,
  .empty-cart-mobile img {
    max-height: 90px;
  }
  .empty-cart p,
  .empty-cart-mobile p {
    font-size: 14px;
    color: var(--grey-light);
  }
  .empty-cart-mobile {
    overflow: hidden;
  }
  .relative {
    position: relative;
  }
  .cart-mobile-product-content-icon :global(svg) {
    height: 30px;
    margin: 0px 0px 4px;
  }
  .cart-mobile-product-content-icon span {
    font-size: 16px;
    text-align: center;
    margin: auto;
  }
  .overlay {
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.78) 20%,
      rgba(0, 0, 0) 100%
    );
    opacity: 0.5;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: fixed;
    z-index: 11;
    top: 0;
    inset: 0px;
    -webkit-tap-highlight-color: transparent;
    right: 0;
    -webkit-transition: width 0.2s ease;
    transition: width 0.2s ease;
  }
  .p-b-safari{
    padding-bottom:80px;
  }
  .p-b-safari-scrollable {
    padding-bottom: 150px!important;
  }
  :global(.cart__button){
    width:40px;
    height: 40px;
  }
  .cashback {
    font-size: 14px;
    font-weight: 500;
    border-radius: 6px;
    background: #60f3d754;
    padding:0.2em;
  }
  .cashback-amount {
    font-size: 15px;
    font-weight: 700;
  }
`;
