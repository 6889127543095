import Button from 'components/utils/Button';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Icon from 'components/utils/Icon';
import useResponsive from 'hooks/useResponsive';
import { setAddToCart } from 'actions/ui';
import CartContent from './cart-content';
import style from './index.style';
import { useRouterWithLang } from 'hooks/useRouterWithLang';

export default function CartModal() {
  const cart = useSelector((state) => state?.cartProducts);
  const dispatch = useDispatch();
  const router = useRouterWithLang();
  const { isMobile } = useResponsive();
  const cartOpen = useSelector((state) => state?.UI?.add_to_cart);

  const closeCart = useCallback(() => {
    dispatch(setAddToCart(false));
  }, [dispatch]);

  const openCart = useCallback(() => {
    dispatch(setAddToCart(true));
  }, [dispatch]);

  const handleClick = useCallback(() => {
    if (
      router.pathname !== '/checkout' &&
      router.pathname !== '/checkout/payment'
    ) {
      openCart();
    } else router.push('/cart');
  }, [openCart, router]);

  return (
    // Non togliere questo id perchè il portal lo utilizza per essere renderizzato nel posto giusto
    <div id="react-portal-cart-modal-container">
      <Button
        className="cart__button action-trasparent relative"
        onClick={handleClick}
      >
        <Icon type={'shopping-cart'} style={{ fontSize: isMobile ? 20 : 22 }} />
        {cart?.length > 0 && (
          <span className="d-f jc-c ai-c counter">{cart?.length}</span>
        )}
      </Button>
      <CartContent />
      <style jsx>
        {`
          @media screen and (max-width: 490px) {
            :global(body) {
              overflow: ${cartOpen ? 'hidden!important' : ''};
              padding-right: ${cartOpen ? '0px!important' : ''};
              min-height: 100vh;
              min-height: -webkit-fill-available;
            }
          }
        `}
      </style>
      <style jsx>{style}</style>
    </div>
  );
}
