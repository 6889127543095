import Button from 'components/utils/Button';
import React, { useCallback, useMemo, useRef } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useTranslation from 'hooks/useTranslation';
import Icon from 'components/utils/Icon';
import useResponsive from 'hooks/useResponsive';
import { ADDRESS_TYPE } from 'tools/constants';
import { setAddToCart } from 'actions/ui';
import { transformRecipient } from 'actions/address';
import { useEffect } from 'react';
import request from 'tools/request';
import Progress from 'components/generic/Progress';
import { isIOS, isSafari } from 'react-device-detect';
import Portal from '../../../utils/Portal';
import ModalCartProduct from './cart-product';
import style from '../index.style';
import { useRouterWithLang } from 'hooks/useRouterWithLang';
import useUserCashback from '../../../../hooks/useUserCashback';

export default function CartContent() {
  const cart = useSelector((state) => state?.cartProducts);
  const { TRANSLATIONS, translationsParameters } = useTranslation();
  const router = useRouterWithLang();
  const PERMISSIONS = useSelector((state) => state.permissions);
  const [carriers, setCarriers] = useState([]);
  const user = useSelector((state) => state?.user);
  const userCatalog = useSelector((state) => state?.user?.userCatalogs)?.[0];
  const dispatch = useDispatch();
  const { isMobile } = useResponsive();
  const cartOpen = useSelector((state) => state?.UI?.add_to_cart);
  const closeCart = useCallback(() => {
    dispatch(setAddToCart(false));
  }, [dispatch]);
  const modalRef = useRef(null);

  const onClickItem = useCallback(() => {
    const link = PERMISSIONS.IS_PRIVATE
      ? `/channel/${userCatalog?._id}/edit/products`
      : `/product`;
    router.push(link);
  }, [PERMISSIONS.IS_PRIVATE, router, userCatalog?._id]);

  const addresses = useSelector((state) =>
    state.addresses.map(transformRecipient)
  );
  const shippingAddress = addresses?.find(
    (address) => address?.type === ADDRESS_TYPE.SHIPPING
  );
  const totalPrices = useMemo(
    () =>
      cart.reduce((acc, el) => acc + el?.model?.bestTaxable * el.quantity, 0),
    [cart]
  );

  const totalWeight = useMemo(
    () => cart.reduce((acc, el) => acc + el.weight * el.quantity, 0),
    [cart]
  );
  useEffect(() => {
    if (
      (shippingAddress?.country?.countrycode || user.countryCode) &&
      cartOpen
    ) {
      request(
        `/api/carrier_fee/country/${
          shippingAddress?.country?.countrycode || user.countryCode
        }/to/${totalWeight}`,
        { useLoader: false }
      ).then((response) => {
        const { data } = response;
        if (data && Array.isArray(data)) {
          setCarriers(
            data.filter(
              (carrier) =>
                !carrier?.carrierData?.[0]?.cod || PERMISSIONS.IS_PRIVATE
            )
          );
        }
      });
    }
  }, [
    totalWeight,
    shippingAddress?.country?.countrycode,
    cartOpen,
    user.countryCode,
    PERMISSIONS.IS_PRIVATE,
  ]);

  const orderTotal = useSelector((state) => state.orderTotal);
  const carriersFree = useMemo(
    () => carriers.find((c) => c?.freeShippingFrom > 0),
    [carriers]
  );

  const { userCashback } = useUserCashback();

  const totalCashback = useMemo(
    () =>
      cart.reduce(
        (acc, el) =>
          acc +
          (((el?.attributes?.cashback && userCashback !== 0
            ? Number(el?.attributes?.cashback)
            : userCashback) *
            el?.model?.bestTaxable) /
            100) *
            el.quantity,
        0
      ),
    [cart, userCashback]
  );

  const handleViewCart = useCallback(() => {
    router.push('/cart');
    closeCart();
  }, [closeCart, router]);

  if (!cartOpen) {
    return null;
  }

  return (
    <Portal
      wrapperId={
        isMobile
          ? 'react-portal-cart-modal-container-mobile'
          : 'react-portal-cart-modal-container'
      }
      onClose={closeCart}
      childrenRef={modalRef}
    >
      <div className={` d-f relative`} ref={modalRef}>
        <div
          className={`${isMobile ? 'cart-mobile' : 'cart'} d-f fd-c ${
            cartOpen ? 'active' : 'noactive'
          }`}
        >
          <div className="cart-header d-f ai-c jc-sb">
            <h5 className="d-f g-5 ai-c jc-c">
              {TRANSLATIONS.cart.title}
              <span>({cart?.length})</span>
            </h5>
            <div className="cart-header-icon d-f jc-c ai-c">
              <Icon type="x" onClick={closeCart} />
            </div>
          </div>
          <div
            className={`${isMobile ? 'cart-wrapper-mobile' : 'cart-wrapper'}`}
          >
            {cart.length > 0 ? (
              <>
                <div
                  className={`scrollable-section ${
                    !carriersFree?.freeShippingFrom && ''
                  }
                  ${isIOS && isSafari ? 'p-b-safari-scrollable' : ''}
                  `}
                >
                  {cart?.map((product) => (
                    <ModalCartProduct key={product._id} product={product} />
                  ))}
                </div>
                <div
                  className={`fixed-box ${isIOS && isSafari && 'p-b-safari'}`}
                >
                  {carriersFree?.freeShippingFrom && (
                    <>
                      {totalPrices < carriersFree?.freeShippingFrom ? (
                        <div
                          className={`shipping-free-text d-f fd-c g-10 fw-w mb-md jc-c ${
                            !isMobile ? '' : ''
                          }`}
                        >
                          <p className="">
                            <Icon type="truck" style={{ marginRight: '4px' }} />
                            {translationsParameters(TRANSLATIONS.cart.youNeed, [
                              <span key="free-shipping">
                                <strong>
                                  {(
                                    carriersFree?.freeShippingFrom - totalPrices
                                  )
                                    .toFixed(2)
                                    .toString()}
                                  €
                                </strong>
                              </span>,
                            ])}
                          </p>
                          <Progress
                            bgcolor={'var(--accent-dark)'}
                            backgroundColor={'#e6f2ff'}
                            completed={
                              ((totalPrices || 0) /
                                carriersFree?.freeShippingFrom) *
                              100
                            }
                          />
                        </div>
                      ) : (
                        <div
                          className={`shipping-free-reached d-f fd-c fw-w mb-md ${
                            !isMobile ? '' : ''
                          }`}
                        >
                          <p className="d-f g-10 ai-c jc-c">
                            <Icon type="check-circle" />
                            <span>
                              <strong>{TRANSLATIONS.cart.shippingFree}</strong>
                            </span>
                          </p>
                        </div>
                      )}
                    </>
                  )}
                  {!!totalCashback && (
                    <div className={'cashback mb-md jc-c d-f g-5'}>
                      <span>{TRANSLATIONS.cart.cashback}</span>{' '}
                      <span className={'cashback-amount'}>
                        {totalCashback.toFixed(2)}
                      </span>
                    </div>
                  )}
                  <div className={'d-f g-5'}>
                    <Button
                      className={'action wide large'}
                      label={TRANSLATIONS.cart.continueShopping}
                      onClick={() => {
                        closeCart();
                      }}
                    />
                    <Button
                      className={'gradient-blue wide large'}
                      label={TRANSLATIONS.cart.viewCart}
                      onClick={handleViewCart}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  className={`${
                    isMobile ? 'empty-cart-mobile' : 'empty-cart'
                  } d-f g-5 fw-w fd-c ai-c jc-c ta-c`}
                >
                  <img src="/images/product/empty-list.svg" alt="empty-list" />
                  <h5>{TRANSLATIONS.cart.empty}</h5>
                  <p>{TRANSLATIONS.cart.browseCatalogue}</p>
                </div>
                <div
                  className={`fixed-box ${isIOS && isSafari && 'p-b-safari'}`}
                >
                  <Button
                    className={'action wide large'}
                    label={TRANSLATIONS.cart.goToCatalogue}
                    onClick={() => {
                      onClickItem();
                      closeCart();
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
        <style jsx>
          {`
            @media screen and (max-width: 490px) {
              :global(body .grid-container) {
                overflow: ${cartOpen ? 'hidden!important' : ''};
                padding-right: ${cartOpen ? '0px!important' : ''};
                min-height: 100vh;
                min-height: -webkit-fill-available;
              }
            }
          `}
        </style>
        <style jsx>{style}</style>
      </div>
    </Portal>
  );
}
