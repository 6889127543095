const Progress = (props) => {
  const { bgcolor, completed, backgroundColor } = props;

  const containerStyles = {
    height: 10,
    width: '100%',
    backgroundColor: backgroundColor,
    borderRadius: 50,
  };

  const fillerStyles = {
    height: '100%',
    width: `${completed}%`,
    backgroundColor: bgcolor,
    borderRadius: 'inherit',
    textAlign: 'right',
  };

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}></div>
    </div>
  );
};

export default Progress;
