import css from 'styled-jsx/css';

export default css`
  .product {
    display: flex;
    padding: var(--margin-md) 0 ;
    margin-top: 
    margin-bottom: var(--margin-md);
    border-bottom: var(--line);
    padding: 0.5em 0;
  }
  .product:last-child {
    border: none;
    padding-bottom: 0;
  }
  .image-wrapper {
   width:120px;
  }
  .image-wrapper img {
    border: 1px solid #eee;
  }
  .detail-wrapper {
    flex: 100%;
  }
  .sku {
    line-height: 18px;
    font-size:13px;
    max-width:190px;
    overflow: hidden;
    white-space: nowrap;
  text-overflow: ellipsis;
  }
  .sku .brand{
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
  }
  .remove-btn {
    flex: 0 1 30px;
    padding-left: 10px;
    text-align: right; 
    cursor: pointer; 
  } 
  .icon-wrapper {
    display: inline-block;
    width: 36px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    border-radius: 100%;
    transition: .4s;
    position: relative;
  }
  .remove-btn:hover .icon-wrapper {
    background: var(--input-hover);
  }
  .remove-btn :global(.icon) {
    display: inline-block;
    transform-origin: center center;
    transition: .4s;  
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    line-height: 36px;   
    backface-visibility: hidden;
    transform: translateZ(0);
    -webkit-font-smoothing: subpixel-antialiased; 
    will-change: transform;
    font-size:16px;
  }
  .remove-btn:hover :global(.icon) {
    transform: scale(1.1);          
  }
  .row {
    width: 100%;
    display: flex;
    border-bottom: var(--line);
    padding: 8px 0;
    justify-content: space-between;
  } 
  .row:last-child {
    border: none;
    padding-bottom: 0;
  }
  .item {
    font-size: 14px;
    font-weight: 500;      
  }
  .item:first-child {
    flex: 2;
  }
  .name{
    overflow: hidden;
    white-space: nowrap;
  text-overflow:ellipsis;
  }
  .label {
      min-height: 20px; 
      margin-bottom: 1em;
  }
  .label:after {
    content: ':';
    margin: 0 8px 0 2px; 
  }
  .cost {
    font-weight: 700;
    font-size: 16px;
  }
`;
